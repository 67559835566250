.intro {
  position: relative;
  padding-top: 78px;
  padding-bottom: 96px;
  color: $color-default-white;
  background-color: $color-black-pearl;

  @include vp--767 {
    padding-top: 134px;
    padding-bottom: 56px;
  }

  &__img {
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: 70% 100%;

      @include vp--767 {
        object-position: 100% 100%;
      }
    }
  }

  .title {
    color: $color-default-white;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 32px;

    @include vp--767 {
      margin-top: 26px;
      margin-bottom: 26px;
    }
  }

  &__column {
    width: calc(50% - 20px);

    &--info {
      @include vp--767 {
        display: none;
      }
    }

    @include vp--767 {
      width: 100%;
    }
  }

  &__about {
    margin: 0;
    max-width: 590px;
    font-size: 20px;
    line-height: 26px;

    @include vp--767 {
      max-width: 100%;
      font-size: 18px;
      line-height: 18px;
    }
  }

  &__info {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }
}

.intro--contacts {
  padding-bottom: 99px;

  @include vp--767 {
    padding-top: 104px;
    padding-bottom: 120px;
  }

  .title {
    margin-bottom: 16px;

    @include vp--767 {
      margin-bottom: 23px;
    }

    span {
      opacity: 0.6;

      @include vp--767 {
        display: block;
      }
    }
  }

  .intro__about {
    max-width: 810px;

    @include vp--767 {
      margin: 0;
    }
  }
}

.intro--back-to {
  padding: 32px 0 90px;

  @include vp--1023 {
    padding-top: 50px;
  }

  @include vp--767 {
    padding-top: 26px;
  }

  .btn--link-back {
    margin-bottom: 30px;
  }

  .intro__about {
    max-width: 75%;

    &--article {
      @include vp--1023 {
        max-width: 100%;
      }
    }
  }

  .intro__row {
    margin-bottom: 36px;

    @include vp--767 {
      margin-top: 0;
      margin-bottom: 24px;

      &--article {
        margin-top: 24px;
      }
    }
  }

  .title {
    @include vp--767 {
      max-width: 75%;
    }
  }
}
