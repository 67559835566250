.container {
  width: 100%;
  max-width: 1348px;
  margin: 0 auto;
  padding: 0 33px;

  @include vp--767 {
    padding: 0 11px;
  }

  &--no-padding {
    @include vp--767 {
      padding: 0;
    }
  }
}
