.services {
  position: relative;
  padding-top: 44px;
  padding-bottom: 104px;

  @include vp--767 {
    padding-top: 24px;
    padding-bottom: 50px;
  }

  &__description {
    margin: 0;
    margin-top: 27px;
    margin-bottom: 51px;
    max-width: 700px;
    font-size: 24px;
    line-height: 24px;
    color: $color-black-pearl;

    @include vp--767 {
      margin-top: 8px;
      margin-bottom: 25px;
      font-size: 18px;
      line-height: 18px;
      max-width: 320px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__slider {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      right: -475px;
      top: 0;
      width: 30%;
      height: 100%;
      background: linear-gradient(to right, $color-transparent, $color-default-white);
      z-index: 1;
      opacity: 0.8;
      pointer-events: none;

      @include vp--1919 {
        right: -295px;
      }

      @include vp--1439 {
        right: -80px;
      }

      @include vp--1023 {
        display: none;
      }
    }
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    outline: none;
    transition: opacity $trans--300;

    &:hover {
      opacity: 0.7;
      transition: opacity $trans--300;
    }

    svg {
      fill: none;
      stroke: $color-blue;
    }

    &.swiper-button-disabled {
      opacity: 1;

      svg {
        stroke: $color-black-pearl;
      }
    }

    &::after {
      display: none;
    }

    @include vp--767 {
      display: none;
    }
  }

  .swiper-button-prev {
    right: 49px;
    left: auto;
    top: -61px;
  }

  .swiper-button-next {
    right: -7px;
    top: -61px;
  }

  .swiper-pagination-progressbar {
    width: 100%;
    bottom: 0;
    top: auto;
    height: 3px;
    background: $color-transparent;

    &-fill {
      background: $color-black-pearl;
    }
  }
}
