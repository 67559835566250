.funding {
  padding-top: 60px;
  padding-bottom: 84px;

  @include vp--767 {
    padding-top: 28px;
    padding-bottom: 56px;
  }

  .container {
    position: relative;
    display: flex;

    @include vp--767 {
      display: block;
    }
  }

  &__img {
    margin-right: 73px;
    padding: 76px;
    padding-left: 72px;
    padding-top: 56px;
    padding-bottom: 56px;
    background-color: $color-white-smoke;

    @include vp--1023 {
      padding: 0 95px;
      padding-top: 46px;
      padding-bottom: 60px;

      img {
        width: 120px;
        height: 120px;
      }
    }

    @include vp--767 {
      position: absolute;
      padding: 0 38px;
      padding-left: 32px;
      padding-top: 24px;
      padding-bottom: 24px;
      right: -104px;
      top: 54px;

      img {
        width: 81px;
        height: 81px;
      }
    }

    @include vp--374 {
      right: -104px;

      img {
        width: 41px;
        height: 41px;
      }
    }
  }

  &__wrapper {
    padding-top: 40px;

    @include vp--767 {
      padding-top: 52px;
      border-top: 1px solid $color-black-russian;
    }
  }

  &__description {
    margin: 0;
    margin-top: 24px;
    margin-bottom: 34px;
    max-width: 790px;
    font-size: 24px;
    line-height: 24px;
    color: $color-black-pearl;

    @include vp--1023 {
      max-width: 490px;
    }

    @include vp--767 {
      max-width: 230px;
      font-size: 18px;
      line-height: 18px;
      margin-top: 10px;
      margin-bottom: 24px;
    }

    @include vp--374 {
      max-width: 220px;
    }
  }
}
