.custom-input {
  position: relative;

  display: flex;
  width: 100%;
  padding: 0;

  label {
    display: flex;
    align-items: center;
    width: 100%;

    position: relative;

    transform: translateZ(0);

    input,
    textarea {
      flex-grow: 1;
      min-height: 52px;
      padding: 24px 21px 8px 21px;
      border: none;
      border-bottom: 1px solid $color-raven;

      outline: none;

      background-color: $color-white-smoke-2;

      transition: border $trans--300;

      font-family: $font-family--helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-black-pearl;

      @include vp--767 {
        padding: 24px 16px 8px 16px;
      }

      &:hover {
        border-bottom: 1px solid $color-black-pearl;
      }

      &:focus {
        border-bottom: 1px solid $color-black-pearl;
      }

      &:focus + .custom-input__placeholder {
        transform: translateY(-17px) scale(0.75);
      }
    }

    input:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0px 100px $color-white-smoke-2 inset;
    }

    textarea {
      height: 215px;
      padding-bottom: 48px;
    }

    .custom-input__placeholder {
      position: absolute;
      bottom: 15px;
      left: 21px;
      font-family: $font-family--helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-grey;
      pointer-events: none;
      transform-origin: left bottom;
      transition: transform .3s ease;
      transition: transform .3s ease,-webkit-transform .3s ease;

      @include vp--767 {
        left: 16px;
      }

      &.moved {
        transform: translateY(-17px) scale(0.75);
      }
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: calc(100% + 3px);

    font-family: $font-family--helvetica;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    color: $color-fire-brick;

    opacity: 0;

    transition: opacity $trans--300;

    @include vp--1023 {
      top: calc(100% + 2px);
      line-height: 18px;
    }
  }

  &.not-empty {
    input,
    textarea {
      border-bottom: 1px solid $color-black-pearl;
    }
  }

  &.is-invalid {
    input,
    textarea {
      background-color: rgba($color-fire-brick, 0.1);
    }

    input:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0px 100px $color-fire-brick-2 inset;
    }

    .custom-input__error {
      opacity: 1;
    }
  }
}

.custom-input--textarea {
  label {
    textarea {
      &:focus + .custom-input__placeholder {
        transform: translateY(-17px) scale(0.75);
      }
    }

    .custom-input__placeholder {
      bottom: auto;
      top: 18px;
    }
  }

  .custom-input__count-signs {
    position: absolute;
    left: 21px;
    bottom: 20px;

    font-family: $font-family--helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-grey;

    @include vp--767 {
      left: 16px;
    }
  }
}

.custom-input--tel {
  z-index: 1;

  @media (max-width: 370px) {
    label
      .custom-input__placeholder {
        bottom: auto;
        width: 55px;
      }
  }   
}
