.features {
  padding-bottom: 20px;

  @include vp--1023 {
    padding-bottom: 12px;
  }

  & > .container {
    position: relative;

    @include vp--767 {
      padding: 0;
    }
  }

  &__top {
    padding-top: 40px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba($color-black-russian, 0.2);

    @include vp--1023 {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 550;
    font-size: 26px;
    line-height: 26px;
    color: $color-black-pearl;

    @include vp--1023 {
      font-size: 18px;
      line-height: 18px;
    }

    @include vp--767 {
      max-width: 300px;
    }
  }

  &__list {
    margin: 0;
    margin-top: 36px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);

    @include vp--767 {
      flex-wrap: nowrap;
      margin-top: 32px;
      padding-left: 11px;
      width: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__item {
    width: calc(33.333333% - 30px);
    margin-right: 30px;
    margin-bottom: 20px;

    @include vp--767 {
      min-width: 218px;
      margin-bottom: 0;
    }
  }

  &__bg {
    position: absolute;
    right: 100px;
    bottom: -360px;
    z-index: -1;

    @include vp--767 {
      right: 0;
      bottom: -150px;
    }

    img {
      @include vp--767 {
        width: 298px;
        top: 0;
        left: 120px;
      }
    }
  }
}
