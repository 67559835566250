.custom-toggle {
  position: relative;

  display: flex;
  padding: 3px 0px 2px 0;

  @include vp--767 {
    padding-top: 8px;
    padding-bottom: 4px;
  }

  &__error {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;

    font-family: $font-family--helvetica;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    color: $color-fire-brick;

    opacity: 0;

    transition: opacity $trans--300;

    @include vp--1023 {
      top: 100%;
      line-height: 18px;
    }

    @include vp--767 {
      top: calc(100% - 4px);
    }
  }

  input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  label {
    position: relative;

    padding-left: 37px;

    cursor: pointer;

    span {
      font-family: $font-family--helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba($color-black-pearl, 0.7);
      user-select: none;
    }

    a {
      text-decoration: underline;
      color: rgba($color-black-pearl, 0.7);

      opacity: 1;
      transition: opacity $trans--300;
  
      &:hover {
        opacity: 0.75;
        color: $color-neon-blue;
        text-decoration: none;
      } 
    }
  }

  &--checkbox {
    label {
      &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;

        width: 23px;
        height: 23px;

        border: none;
        border-bottom: 1px solid $color-raven;

        background-color: $color-white-smoke-2;
      }

      &::after {
        display: none;
        content: "";
        position: absolute;
        top: 6px;
        left: 4px;

        width: 14px;
        height: 9px;
        border-left: 2px solid $color-blue;
        border-bottom: 2px solid $color-blue;

        transform: rotate(-45deg);

        background-color: $color-transparent;

        transition: background-color $trans--300;
      }
    }
  }

  &--radio {
    label {
      &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;

        width: 16px;
        height: 16px;

        border: 1px solid $color-eucalyptus;
        border-radius: 50%;

        transition: border $trans--300;
      }

      &::after {
        content: "";
        position: absolute;
        top: 6px;
        left: 4px;

        width: 8px;
        height: 8px;

        background-color: $color-transparent;
        border-radius: 50%;

        transition: background-color $trans--300;
      }
    }
  }

  input:checked + label::after {
    display: block;
    background-color: $color-transparent;
  }

  input:disabled + label::before {
    opacity: 0.5;
  }

  &.is-invalid {
    .custom-toggle__error {
      opacity: 1;
    }
  }
}
