.help-form {
  form > * + * {
    margin-top: 31px;

    @include vp--767 {
      margin-top: 23px;
    }
  }

  input[type= "hidden"] + .custom-input {
    margin-top: 0;

    @include vp--767 {
      margin-top: 0;
    }
  }

  &__row {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: space-between;

    .custom-input,
    .custom-select,
    .custom-select-input {
      width: calc((100% - 38px) /2);
    }

    @include vp--1023 {
      flex-direction: column;

      .custom-input,
      .custom-select,
      .custom-select-input {
        width: 100%
      }

      & > * + * {
        margin-top: 32px;

        @include vp--767 {
          margin-top: 23px;
        }
      }
    }
  }
}
