.team {
  padding-top: 80px;
  padding-bottom: 48px;

  @include vp--767 {
    padding-top: 54px;
    padding-bottom: 48px;
  }

  &__list {
    list-style: none;
    margin: 0;
    margin-top: 30px;
    padding: 0;
    display: flex;
    width: calc(100% + 39px);

    @include vp--1023 {
      width: calc(100% + 25px);
    }

    @include vp--767 {
      margin-top: 18px;
      padding-left: 11px;
    }
  }

  &__item {
    width: calc(33.333333% - 39px);
    margin-right: 39px;

    @include vp--1023 {
      width: calc(33.333333% - 25px);
      margin-right: 25px;
    }

    @include vp--767 {
      min-width: 243px;
      margin-right: 25px;
    }
  }

  .container {
    &:last-of-type {
      @include vp--767 {
        padding: 0;
      }
    }
  }

  &__slider {
    position: relative;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-pagination-progressbar {
    bottom: -46px;
    top: auto;
    height: 3px;
    background: $color-transparent;
    border-bottom: 1px solid rgba($color-black-pearl, 0.2);

    &-fill {
      background: $color-black-pearl;
    }
  }

  .news-articles-card {
    opacity: 1;
    width: 100%;

    .news-articles-card__title {
      padding: 0;
      border-bottom: 0;
    }

    img {
      width: 100%;
      @include vp--767 {
        height: 138px;
      }
    }
  }

  .news-articles-card__description {
    margin-top: 24px;
    font-size: 14px;
    line-height: 16px;
    color: rgba($color-eclipse, 0.7);

    @include vp--1023 {
      margin-top: 12px;
    }

    @include vp--767 {
      margin-top: 6px;
      line-height: 18px;
    }
  }
}
