.btn {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  max-width: 280px;
  padding: 11px 25px;
  border-radius: 2px;
  border: none;

  font-family: $font-family--montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: $color-default-white;

  background-color: $color-blue;

  cursor: pointer;
  opacity: 1;

  transition: opacity $trans--300, background-color $trans--300;

  @include vp--767 {
    font-size: 13px;
    line-height: 16px;
  }
  &:focus,
  &:hover {
    opacity: 0.75;
  }

  [disabled],
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  svg {
    flex-shrink: 0;

    margin-left: 12px;
  }
}

.btn--transparent {
  padding: 10px 24px;
  border: 1px solid $color-silver;

  color: $color-black-pearl;

  background-color: $color-transparent;
}

.btn--full-width {
  max-width: none;
  width: 100%;

  font-family: $font-family--helvetica;
  font-size: 14px;
  font-weight: 550;
  line-height: 14px;
}

.btn--opacity {
  background-color: rgba($color-transparent, 0.15);
}

.btn--arrow {
  svg {
    transform: rotate(90deg);
  }
}

.btn--link {
  max-width: 280px;
  display: inline-block;
}

.btn--link-full-width {
  display: flex;
}

.btn--search {
  display: flex;
  align-items: center;
  width: 34px;
  height: 38px;

  max-width: none;
  padding: 0;
  border-radius: 0;

  background-color: $color-transparent;
  outline: none;

  color: $color-default-black;

  &:hover,
  &:focus {
    svg {
      opacity: 1;
      transition: opacity $trans--300;
    }
  }

  svg {
    fill: transparent;
    color: initial;
    opacity: 0.5;
    transition: opacity $trans--300;
  }
}

.btn--link-back {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: center;
  padding: 0;
  max-width: fit-content;

  font-family: $font-family--helvetica;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: $color-rolling-stone;

  background-color: transparent;

  svg {
    margin-right: 8px;
    margin-left: 0;
  }

  &:hover {
    opacity: 0.8;
    background-color: transparent !important;
  }

  &:active {
    opacity: 1;
  }
}

.btn--gray {
  width: 195px;
  padding: 12px 15px 10px 21px;
  background-color: rgba(255, 255, 255, 0.15);

  svg {
    transform: rotate(90deg);
  }

  @include vp--767 {
    width: 178px;
    padding: 11px 0 10px 18px;
    background-color: $color-baltic-sea;

    svg {
      margin-right: 13px;
      margin-left: 7px;
    }
  }
}

.btn--blue-hover {
  &:not([disabled]):not(.btn--disabled):focus,
  &:not([disabled]):not(.btn--disabled):hover {
    opacity: 1;
    background-color: rgba($color-blue, 0.75);
  }
}
