.atypical-button {
  position: relative;

  width: 472px;
  height: 377px;

  .atypical-button__wrap {
    position: absolute;
    top: -38px;
    left: -38px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 453px;
    height: 453px;
  }

  a {
    z-index: 1;

    width: 66px;
    height: 66px;
    border-radius: 50%;
    border: none;
    padding: 0;

    box-shadow: none;

    background-color: $color-transparent;

    cursor: pointer;

    transition: transform $trans--600, box-shadow $trans--600;

    &:before {
      content: "";

      position: absolute;

      transform: translate(calc(68px / 2 - 378px / 2), calc(68px / 2 - 378px / 2));
      width: 378px;
      height: 378px;
      border-radius: 50%;
      border: 1px solid rgba($color-black-pearl, 0.05);

      background-color: $color-transparent;

      pointer-events: none;
    }

    &:after {
      content: "";

      position: absolute;
      transform: translate(calc(-98px / 2), calc(-74px / 2 - 168px / 2));

      width: 168px;
      height: 168px;
      border-radius: 50%;
      border: 1px solid rgba($color-black-pearl, 0.1);

      background-color: $color-transparent;

      pointer-events: none;
    }

    &:focus,
    &:hover {
      transform: scale(1.2);

      box-shadow: 0px 10px 20px rgba($color-blue, 0.18),
                  0px 2px 6px rgba($color-blue, 0.12),
                  0px 0px 1px rgba($color-default-black, 0.4);
    }

  }

  span {
    position: absolute;
    top: 47%;
    left: 51%;

    display: inline-block;
    margin: 0;

    font-family: $font-family--helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: $color-black-pearl;
  }
}
