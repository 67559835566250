.project-card {
  .title {
    padding-top: 54px;
    padding-bottom: 27px;
    color: $color-default-white;
    border-bottom: 1px solid rgba($color-default-white, 0.3);

    @include vp--767 {
      padding-top: 32px;
      padding-bottom: 16px;
      border: none;
    }
  }

  .container {
    &:last-of-type {
      @include vp--767 {
        padding: 0;
      }
    }
  }

  &__grid {
    margin-top: 39px;
    margin-bottom: 48px;
    display: grid;
    grid-template-columns: 622px repeat(2, 1fr);
    gap: 38px;
    row-gap: 28px;

    @include vp--1279 {
      grid-template-columns: 2fr repeat(2, 1fr);
    }

    @include vp--1023 {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  &__block {
    @include vp--767 {
      padding: 0 11px;
    }

    &--statistics {
      @include vp--1023 {
        width: 50%;
      }
    }
  }

  &__block--image {
    @include vp--1023 {
      display: none;
    }

    &-large {
      @include vp--1023 {
        order: -1;
        flex-grow: 1;
        padding: 0;
      }

      @include vp--767 {
        padding: 0;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 292px;
      object-fit: cover;

      @include vp--767 {
        height: 230px;
      }
    }
  }

  &__description,
  &__capture,
  &__feature {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }

  &__features {
    margin: 0;
    margin-top: 32px;
    padding: 0;
    list-style: none;

    @include vp--1023 {
      margin-top: 24px;
    }
  }

  &__feature {
    display: flex;
    margin-bottom: 12px;

    &::before {
      display: block;
      content: "— ";
      margin-right: 7px;
    }
  }

  &__slogan {
    margin: 0;
    margin-bottom: 12px;
    font-weight: 550;
    font-size: 28px;
    line-height: 28px;

    @include vp--1023 {
      margin-top: 12px;
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 18px;
    }
  }

  &__description {
    @include vp--1023 {
      margin-top: 40px;

      br {
        display: none;
      }
    }
  }

  &__capture {
    @include vp--767 {
      line-height: 14px;
    }
  }
}
