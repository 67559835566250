.office {
  display: none;

  flex: 1 1 auto;
  min-height: 300px;

  font-size: 13px;
  line-height: 13px;
  color: $color-default-white;

  border-top: 1px solid $color-cod-grey;

  overflow: hidden;
}

.office__bg {
  position: relative;

  padding: 31px 11px;

  .office__bg-img {
    position: absolute;
    top: -50px;
    left: 100px;

    width: 100%;

    opacity: 0.1;
  }
}

.office__department {
  display: flex;
  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 0;
  }
}

.office__flag {
  margin-right: 13px;

  img {
    width: 25px;
    height: 17px;
    object-fit: cover;
    background-color: $color-black-pearl;
  }
}

.office__title {
  font-weight: 700;
}

.office__address {
  max-width: 292px;
  margin: 7px 0 0;
}
