.footer {
  background: $color-default-white;
  margin-top: 56px;

  @include vp--1279 {
    margin-top: 30px;
  }

  @include vp--767 {
    margin-top: 56px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__top {
    position: relative;
    display: flex;
    padding-bottom: 40px;
    padding-left: 8px;

    @include vp--1279 {
      justify-content: space-between;
      padding-left: 0;
    }

    @include vp--1023 {
      justify-content: center;
      padding-bottom: 33px;
    }

    &::before {
      position: absolute;
      content: "";
      height: 1px;
      background: $color-black-pearl;
      bottom: 0;
      left: -100%;
      right: -100%;
      opacity: 0.2;

      @include vp--767 {
        display: none;
      }
    }
  }

  &__top-header {
    display: block;
    font-family: $font-family--helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: $color-black-pearl;
    margin: 0;
    margin-bottom: 11px;

    &:hover {
      opacity: 0.4;
    }
  }

  &__top-text {
    font-family: $font-family--helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: $color-black-pearl;
    margin: 0;
    display: block;
  }

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;

    a:hover {
      opacity: 0.4;
    }

    li {
      padding-bottom: 10px;
    }
  }

  &__offices {
    max-width: 300px;
    width: 100%;
    margin-right: 50px;

    @include vp--1279 {
      max-width: 200px;
      margin-right: 50px;
    }

    @include vp--1023 {
      display: none;
    }
  }

  &__office {
    margin-bottom: 50px;

    @include vp--1279 {
      max-width: 210px;
      margin-bottom: 35px;
    }

    .footer__top-header,
    .footer__top-text {
      padding-left: 38px;
    }

    .footer__top-text {
      line-height: 18px;
    }
  }

  &__office-header {
    display: flex;
    position: relative;

    img {
      position: absolute;
      top: -7px;
      left: -7px;

      @include vp--1279 {
        top: -3px;
      }
    }
  }

  &__menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 620px;
    width: 100%;
    margin-right: 20px;

    @include vp--1279 {
      max-width: 420px;
      margin-right: 10px;
    }

    @include vp--1023 {
      display: none;
    }
  }

  &__about {
    margin-bottom: 40px;
  }

  &__transport {
    margin-bottom: 40px;

    br {
      display: none;

      @include vp--1279 {
        display: inline;
      }
    }
  }

  &__other &__top-header {
    display: block;
    margin-bottom: 16px;
  }

  &__contacts {
    box-sizing: border-box;
    height: fit-content;

    @include vp--1023 {
      width: 100%;
    }

    .footer__top-header {
      padding-top: 18px;
      padding-left: 24px;

      @include vp--767 {
        font-weight: 550;
        font-size: 16px;
        line-height: 16px;
        padding-top: 23px;
        padding-left: 15px;
      }
    }

    .accordion {
      @include vp--1023 {
        max-width: 100%;
      }
    }
  }

  &__contacts-item {
    padding: 0;
    padding-left: 25px;
    padding-right: 20px;
    margin-bottom: 0;

    @include vp--767 {
      padding: 0 15px;
    }

    &:hover {
      background-color: $color-white-smoke;
    }

    &:active {
      background-color: transparent;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      color: $color-raven;
    }

    &.is-open {
      background-color: $color-white-smoke;
    }

    .accordion__wrapper {
      padding-bottom: 20px;
    }

    .btn {
      color: $color-default-white;
      margin-top: 20px;

      @include vp--767 {
        max-width: 100%;
      }
    }
  }

  &__contacts-button {
    position: relative;
    border: 0;
    background: transparent;
    font-family: $font-family--helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: $color-dolphin;
    text-align: left;
    padding: 14px 0;
    padding-right: 15px;
    width: 100%;
    cursor: pointer;

    &:hover {
      color: $color-black-pearl;
    }

    &:active {
      color: $color-dolphin;
    }

    svg {
      position: absolute;
      right: 0;
      fill: none;
      color: $color-blue;
    }

    @include vp--767 {
      padding: 11px 0;
      padding-right: 15px;
    }
  }
}

.is-open .footer__contacts-button {
  color: $color-black-pearl;

  svg {
    transform: rotate(90deg);
    color: $color-black-pearl;
  }
}

.footer__bottom {
  padding-top: 50px;
  display: flex;

  @include vp--1279 {
    padding-top: 40px;
  }

  @include vp--767 {
    padding-top: 0;
    flex-direction: column;
    justify-content: center;
  }
}

.footer__left {
  width: 27%;
  margin-right: 58px;
  padding-left: 10px;

  @include vp--1279 {
    width: 50%;
    margin-right: 24px;
    padding-left: 0;
  }

  @include vp--767 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 29px;
    opacity: 0.6;
  }
}

.footer__left svg {
  display: block;
  margin-bottom: 12px;
  margin-left: -3px;
  color: $color-black-pearl;

  @include vp--1279 {
    margin-left: 0;
  }

  @include vp--767 {
    margin-left: -1px;
    margin-bottom: 9px;
  }
}

.footer__left p {
  font-family: $font-family--helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: $color-black-pearl;
  opacity: 0.9;
  margin: 0;
}

.footer__right p {
  font-family: $font-family--helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: $color-black-pearl;
  opacity: 0.6;
  margin: 0;
  margin-bottom: 28px;

  @include vp--1279 {
    padding-right: 40px;
  }

  @include vp--767 {
    padding-right: 0;
  }
}
