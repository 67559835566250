.network {
  position: relative;
  padding-bottom: 150px;
  margin-top: 80px;
  margin-bottom: 26px;

  @include vp--1279 {
    margin-top: 44px;
    margin-bottom: 0;
  }

  @include vp--1023 {
    padding-bottom: 130px;
  }

  @include vp--767 {
    margin-top: 53px;
    padding-bottom: 0;
  }

  &__background {
    max-width: 700px;
    width: 100%;
    position: absolute;
    top: 27px;
    left: 41px;
    z-index: -1;

    @include vp--1279 {
      max-width: 525px;
      top: 100px;
      left: 84px;
    }

    @include vp--767 {
      min-width: 454px;
      top: 0;
      left: 62px;
    }

    img {
      width: 100%;
    }
  }

  .btn {
    width: 175px;
  }
}

.network__wrapper {
  position: relative;
  display: flex;

  @include vp--767 {
    flex-direction: column;
  }
}

.network__left {
  margin-right: 68px;
  margin-left: 8px;

  @include vp--1279 {
    margin-right: 45px;
    margin-left: 0;
    max-width: 377px;
  }

  @include vp--767 {
    margin-right: 0;
    max-width: 300px;
  }

  &-text {
    font-family: $font-family--helvetica;
    font-style: normal;
    font-weight: 550;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.01em;
    color: $color-black-pearl;
    margin: 0;
    width: 595px;

    @include vp--1279 {
      font-size: 35px;
      line-height: 50px;
      width: 370px;
    }

    @include vp--767 {
      font-size: 24px;
      line-height: 32px;
      width: 100%;
    }
  }
}

.network__right {
  padding-top: 2px;

  @include vp--767 {
    padding-top: 0;
  }
}

.network__right p {
  font-family: $font-family--helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $color-black-pearl;
  margin: 0;
}

.network__right-about {
  @include vp--767 {
    display: none;
  }
}

.network__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;
  margin-top: 51px;
  margin-bottom: 29px;

  @include vp--1279 {
    margin-bottom: 8px;
    margin-top: 30px;
    max-width: 530px;
  }

  @include vp--767 {
    margin-bottom: 0;
    margin-top: 48px;
    justify-content: start;
  }
}

.network__item {
  max-width: 282px;
  margin-bottom: 25px;

  &:last-child {
    margin-right: 0;
  }

  @include vp--767 {
    margin-right: 30px;
    max-width: 152px;
  }
}

.network__item p {
  font-family: $font-family--helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: $color-black-pearl;

  @include vp--1279 {
    font-size: 16px;
    line-height: 16px;
    max-width: 235px;
  }

  &:first-child {
    font-family: $font-family--helvetica;
    font-style: normal;
    font-weight: 550;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: $color-black-pearl;
    margin-bottom: 18px;

    @include vp--1279 {
      font-size: 34px;
      line-height: 34px;
      margin-bottom: 11px;
    }

    @include vp--767 {
      font-size: 24px;
      line-height: 24px;
    }
  }
}
