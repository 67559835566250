.pic-card {
  position: relative;
  width: 402px;
  height: 429px;
  padding: 0 32px;
  padding-bottom: 40px;
  transition: box-shadow $trans--300;

  @include vp--767 {
    width: 243px;
    height: 259px;
    padding: 0 16px;
    padding-bottom: 24px;
  }

  a:hover & {
    box-shadow: -10px 10px 0 $color-neon-blue;
    transition: box-shadow $trans--300;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__title {
    position: relative;
    margin: 0;
    margin-bottom: 20px;
    max-width: fit-content;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: $color-default-white;

    @include vp--767 {
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 18px;
    }

    &::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 0;
      bottom: -5px;
      left: 0;
      background-color: $color-neon-blue;
      transition: width $trans--300;

      a:hover & {
        width: 100%;
        transition: width $trans--300;
      }
    }
  }

  &__description {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: $color-default-white;

    @include vp--767 {
      line-height: 18px;
      color: rgba($color-default-white, 0.7);
    }
  }

  &__img {
    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: cover;
      transition: filter $trans--300;

      a:hover & {
        filter: brightness(0.6) contrast(0.8);
        transition: filter $trans--300;
      }
    }
  }
}
