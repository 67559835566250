.opportunities {
  .container {
    padding-top: 50px;

    @include vp--767 {
      padding-top: 37px;
    }

    &:last-of-type {
      padding-top: 0;

      @include vp--1023 {
        padding: 0;
      }
    }
  }

  &__description {
    margin: 0;
    margin-top: 20px;
    max-width: 840px;
    font-size: 24px;
    line-height: 24px;
    color: $color-black-pearl;

    @include vp--767 {
      margin-top: 8px;
      font-size: 18px;
      line-height: 18px;
    }
  }

  &__wrapper {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr) 2fr;
    column-gap: 20px;
    padding-bottom: 56px;
    border-bottom: 1px solid $color-black-russian;

    & > * {
      align-self: flex-end;

      @include vp--1023 {
        align-self: stretch;
      }
    }

    @include vp--1023 {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      margin-top: 34px;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__analytics,
  &__comfort,
  &__management {
    padding: 32px;
    padding-top: 30px;
    padding-right: 16px;
    padding-bottom: 50px;
    color: $color-default-white;

    @include vp--767 {
      padding: 36px 11px;
      padding-bottom: 14px;
    }
  }

  &__comfort {
    max-width: 292px;
    background-color: $color-neon-blue;

    @include vp--1023 {
      width: 50%;
      max-width: 50%;
    }
  }

  &__analytics {
    max-width: 292px;
    padding-bottom: 32px;
    background-color: $color-black-pearl-2;

    svg {
      margin-top: 15px;

      @include vp--1023 {
        display: block;
        margin-left: auto;
        margin-right: 5px;
        margin-top: 0;
        width: 60px;
      }
    }

    @include vp--1023 {
      width: 50%;
      max-width: 50%;
    }

    @include vp--767 {
      .opportunities__title {
        max-width: 120px;
        margin-bottom: 0;
      }
    }
  }

  &__management {
    padding-bottom: 56px;
    color: $color-black-russian;
    background-color: $color-white-smoke;

    .opportunities__list {
      color: $color-black-pearl;
    }

    @include vp--1023 {
      width: 100%;
    }

    @include vp--767 {
      padding-bottom: 42px;

      .opportunities__title {
        max-width: 300px;
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    @include vp--767 {
      max-width: 343px;
    }
  }

  &__item {
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 20px;
    max-width: 530px;

    &:last-child {
      margin-bottom: 0;
    }

    @include vp--767 {
      margin-bottom: 10px;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 24px;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;

    @include vp--767 {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
}
