.table {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  &__head {
    color: $color-default-white;
    background-color: $color-black-pearl-2;

    td {
      min-width: 198px;
      padding: 10px;
      font-weight: 550;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      border: 0;

      &:last-of-type {
        padding-right: 32px;

        @include vp--767 {
          padding-right: 14px;
        }
      }

      &:first-of-type {
        padding: 0;
      }

      @include vp--767 {
        min-width: auto;
        padding-right: 22px;
      }
    }
  }

  &__body {
    color: $color-black-pearl-2;

    td {
      padding: 11px 16px;

      &:first-of-type {
        @include vp--767 {
          padding-right: 0;
        }
      }

      @include vp--767 {
        padding: 11px 10px;
        padding-bottom: 12px;
        padding-right: 24px;
      }

      &:last-of-type {
        padding-right: 36px;

        @include vp--767 {
          padding-right: 14px;
        }
      }
    }
  }

  &__title--desktop {
    @include vp--767 {
      display: none;
    }
  }

  &__condition-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: rgba($color-black-pearl, 0.4);

    @include vp--767 {
      margin-left: 0;
    }
  }

  &__row {
    border-bottom: 1px solid rgba($color-black-pearl, 0.2);
    transition: background-color $trans--300;
    cursor: pointer;

    @include vp--767 {
      position: relative;
      padding: 0 11px;
    }

    &:hover {
      background-color: rgba($color-gainsboro, 0.25);
      transition: background-color $trans--300;
    }

    &:first-child {
      td {
        padding-top: 24px;

        @include vp--767 {
          padding-top: 10px;
        }
      }
    }

    &:last-child {
      border-bottom: 0;

      @include vp--767 {
        &::after {
          display: none;
        }
      }
    }

    &:nth-child(2) {
      @include vp--767 {
        td {
          vertical-align: middle;
        }
      }
    }

    &-title {
      display: block;
      margin-bottom: 11px;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;

      @include vp--767 {
        margin-bottom: 12px;
      }
    }

    &-text {
      font-size: 14px;
      line-height: 20px;
    }

    &-condition {
      text-align: center;
      vertical-align: bottom;

      @include vp--767 {
        vertical-align: bottom;
      }
    }

    &-icon {
      vertical-align: bottom;
    }
  }

  &__icon {
    width: 27px;
    height: 27px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      fill: none;
    }

    &--check {
      background-color: $color-han-purple;
    }

    &--cross {
      background-color: $color-black-pearl-2;
    }
  }
}
