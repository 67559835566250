.title {
  margin: 0;
  font-weight: 550;
  font-size: 40px;
  line-height: 60px;
  color: $color-black-pearl;
  letter-spacing: -0.01em;

  @include vp--1279 {
    font-size: 36px;
    line-height: 66px;
  }

  @include vp--767 {
    font-size: 24px;
    line-height: 32px;
  }

  &--small {
    font-size: 24px;
    line-height: 24px;
  }
}

.title--mod {
  font-size: 40px;
  line-height: 66px;
}
