.platform {
  background-color: $color-nero;
  color: $color-default-white;

  .container {
    padding-top: 48px;
    padding-bottom: 48px;
    position: relative;
    overflow: hidden;

    @include vp--767 {
      padding-top: 52px;
      padding-bottom: 56px;
    }
  }

  &__img {
    position: absolute;
    right: 12px;
    bottom: 0;
    width: 498px;
    height: 226px;

    @include vp--1023 {
      width: 50%;
      height: auto;
    }

    @include vp--767 {
      width: 253px;
      top: -6px;
      right: -60px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__text {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 25px;
    max-width: 648px;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;

    @include vp--1279 {
      max-width: 400px;
    }

    @include vp--1023 {
      max-width: 350px;
    }

    @include vp--767 {
      margin-top: 26px;
      font-size: 14px;
      line-height: 20px;

      span {
        display: none;
      }
    }
  }

  .title {
    max-width: 345px;
    letter-spacing: normal;
    color: $color-default-white;

    @include vp--1279 {
      line-height: 38px;
    }

    @include vp--767 {
      max-width: 200px;
      line-height: 32px;
      letter-spacing: -0.01em;
    }

    @include vp--374 {
      max-width: 140px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &--light {
    background-color: $color-whisper;
    color: $color-black-pearl;

    .title {
      color: $color-black-pearl;
    }
  }
}

.platform--main {
  position: relative;
  overflow: hidden;

  .container {
    padding-top: 79px;
    padding-bottom: 202px;

    overflow: visible;

    @include vp--1279 {
      padding-top: 52px;
      padding-bottom: 122px;
    }

    @include vp--1023 {
      padding-top: 55px;
      padding-bottom: 27px;
    }

    @include vp--767 {
      padding-top: 54px;
      padding-bottom: 57px;
    }

    .atypical-button {
      position: absolute;
      bottom: -34px;
      left: -122px;
      z-index: 3;

      @include vp--1279 {
        bottom: -95px;
        left: -124px;
      }

      @include vp--1023 {
        display: none;
      }
    }
  }

  .title {
    max-width: 485px;
    font-size: 48px;
    line-height: 66px;
    letter-spacing: -0.007em;

    @include vp--1279 {
      max-width: 400px;
      margin-left: -1px;
      font-size: 36px;
      line-height: 66px;
      letter-spacing: -0.01em;
    }

    @include vp--767 {
      max-width: 160px;
      margin-left: 0;
      font-size: 24px;
      letter-spacing: -0.01em;
    }

    @include vp--374 {
      max-width: 120px;
      font-size: 20px;
    }
  }

  .platform__text {
    max-width: 642px;
    margin-top: 16px;

    @include vp--1279 {
      max-width: 460px;
      margin-top: 10px;
    }

    @include vp--1023 {
      max-width: 400px;
      margin-top: 8px;
    }

    @include vp--767 {
      max-width: none;
      width: 100%;
      margin-top: 24px;
    }
  }

  .btn {
    display: none;

    @include vp--1023 {
      display: flex;
    }
  }

  img {
    position: absolute;
    bottom: 0;
    right: -25px;

    display: block;
    width: 636px;
    height: 382px;

    object-fit: cover;

    @include vp--1279 {
      right: -130px;

      width: 636px;
      height: 382px;
      margin-bottom: -53px;
    }

    @include vp--1023 {
      width: 457px;
      height: 275px;
      margin-bottom: 0;
    }

    @include vp--767 {
      right: -50px;
      top: -6px;

      width: 242px;
      height: 125px;

      object-position: left top;
    }
  }
}
