.modal-search {
  .modal-search__form {
    input {
      width: 100%;
      min-height: 72px;
      padding: 10px 80px 10px 34px;

      border-radius: 3px;
      background-color: $color-whisper;

      font-size: 26px;
      line-height: 26px;
      color: $color-black-pearl;
      font-weight: 300;

      border: none;
      outline: none;

      &::placeholder {
        color: inherit;
        opacity: 0.3;
      }

      @include vp--767 {
        font-size: 18px;
        line-height: 18px;
        padding-left: 20px;
        padding-right: 80px;
      }

      @include vp--389 {
        font-size: 15px;
        line-height: 15px;
        padding-left: 10px;
        padding-right: 80px;
      }
    }
  }

  .modal-search__form form {
    position: relative;
  }

  .modal-search__form .btn--search {
    position: absolute;
    top: 16px;
    right: 40px;

    @include vp--389 {
      right: 16px;
    }

    svg {
      color: $color-default-black;
      opacity: 0.4;
      transform: translate3d(0, 0, 0);

      @include vp--389 {
        width: 30px;
        height: 30px;
      }
    }
  }
}
