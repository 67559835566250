.iti {
  position: relative;
  display: flex;
  width: 100%;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input,
.iti input[type=text],
.iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.iti input::placeholder {
  color: $color-transparent;
}

.iti__flag-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  font-family: $font-family--helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-grey;
  transition: background-color $trans--300;
  cursor: pointer;

}

.iti__selected-flag {
  position: relative;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  padding: 18px 16px 14px 16px;
  border-left: 1px solid rgba(136, 136, 136, 0.16);
  border-right: 1px solid rgba(136, 136, 136, 0.16);
  border-bottom: 1px solid $color-transparent;
  outline: 0;

  @include vp--767 {
    padding: 18px 10px 14px 10px;
  }
}

.iti__arrow {
  flex-shrink: 0;
  width: 16px;
  height: 9px;
  margin-left: auto;

  background-image: url("../img/svg/arrow-contacts.svg");

  transition: transform $trans--300;
}

.iti__arrow--up {
  transform: rotate(180deg);
}

.iti__country-list {
  position: absolute;
  left: 1px;
  z-index: 2;
  list-style: none;
  text-align: left;
  width: calc(100% - 2px);
  padding: 0;
  margin: 0;
  background-color: $color-snow;
  border-bottom: 1px solid $color-raven;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 0px 1px 0px #0000000A,
              0px 0px 2px 0px #0000000F,
              0px 4px 8px 0px #0000000A;

}

.iti__country-list--dropup {
  bottom: auto;
  margin-bottom: 0;
}
@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  display: none;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.iti__country {
  padding: 7px 16px 6px 16px;
  outline: none;

  transition: background-color $trans--300;
}

.iti__country:hover {
  background-color: $color-white-smoke-2;
}

.iti__dial-code {
  color: #999;
}

.iti__flag-box, .iti__country-name,
.iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  display: none;
  margin-right: 6px;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 250px;
  padding-top: 17px;
  padding-bottom: 15px;
  margin-left: 0;

  @include vp--767 {
    padding-left: 61%;
  }

}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 137px;
  width: 90px;

  @include vp--767 {
    left: 34%;
    width: 80px;
  }
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag[aria-expanded="true"] {
  background-color: $color-snow;
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 0;
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em; }

.iti__flag {
  display: none;
  width: 20px;
}
