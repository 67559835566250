.hero {
  color: $color-default-white;

  background-color: $color-black-pearl;

  &__head {
    position: relative;

    display: flex;

    height: 599px;
    border-bottom: 1px solid rgba($color-default-white, 0.1);

    background-color: $color-black-pearl;

    @include vp--1279 {
      height: 415px;
    }

    @include vp--767 {
      height: 300px;
    }

    .container {
      position: relative;
      z-index: 1;
    }
  }

  .hero__head-bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    filter: grayscale(100%);
    overflow: hidden;
    pointer-events: none;

    &::after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      background: rgba(0,0,0,0.5);
    }

    .video {
      position: relative;
      transform: translateY(-50%);
      top: 50%;

      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $color-black-pearl;

      @include vp--1439 {
        width: 130%;
        height: 130%;
        margin-left: -15%;
        margin-top: 0;
      }

      @include vp--1279 {
        width: 160%;
        height: 160%;
        margin-left: -30%;
        margin-top: 0;
      }

      @include vp--1023 {
        width: 130%;
        height: 130%;
        margin-left: -15%;
      }

      @include vp--767 {
        width: 260%;
        height: 260%;
        margin-left: -120%;
        margin-right: 0;
        margin-top: -10%;
      }

      iframe,
      video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }
    }
  }

  &__main-title {
    max-width: 1089px;
    margin: 0;
    margin-top: 9.6%;

    font-family: $font-family--helvetica;
    font-size: 48px;
    font-weight: 550;
    line-height: 60px;
    letter-spacing: -0.01em;

    @include vp--1279 {
      max-width: 694px;
      margin-top: 4.59%;

      font-size: 40px;
      line-height: 52px;

      span {
        display: block;
      }
    }

    @include vp--767 {
      max-width: 73.93%;
      margin-top: 10.67%;

      font-size: 24px;
      line-height: 32px;

      span {
        display: inline;
      }
    }
  }

  &__additional-img {
    position: absolute;
    top: 83.14%;
    right: 0;
    z-index: 2;

    width: 14.58%;
    height: auto;

    @include vp--1279 {
      top: 73.61%;
      right: -5.86%;

      width: 17.19%;
    }

    @include vp--767 {
      top: calc(100% + 118px);
      right: auto;
      left: 71.2%;

      width: 108px;
      height: 158px;
    }

    img {
      position: static;

      max-width: 100%;
    }
  }

  &__content {
    display: flex;

    width: 100%;

    @include vp--767 {
      flex-direction: column;
    }
  }

  &__content-link {
    display: flex;

    width: 50%;
    padding-top: 48px;
    padding-bottom: 63px;
    border-right: 1px solid rgba($color-default-white, 0.1);

    transition: background-color $trans--300;

    &:hover {
      background-color: $color-fire-brick;
      .hero__section__feature {
        opacity: 0.8;
      }
    }

    @include vp--1279 {
      padding-top: 45px;
      padding-bottom: 58px;
    }

    @include vp--767 {
      width: 100%;
      padding-top: 31px;
      padding-bottom: 32px;
      border-right: none;
      border-bottom: 1px solid rgba($color-default-white, 0.1);
    }
  }

  &__content-section {
    max-width: 674px;
    width: 100%;
    margin-left: auto;
    padding-left: 33px;
    padding-right: 33px;

    color: $color-default-white;

    @include vp--767 {
      margin: 0;
      padding-left: 11px;
      padding-right: 11px;
    }
  }

  &__section-head {
    max-width: 485px;
    margin: 0;
    margin-bottom: 24px;

    font-family: $font-family--helvetica;
    font-size: 26px;
    font-weight: 550;
    line-height: 26px;

    @include vp--1279 {
      max-width: 440px;
      margin-bottom: 16px;

      font-size: 24px;
      line-height: 24px;
    }

    @include vp--767 {
      max-width: none;
      margin-bottom: 14px;

      font-size: 18px;
      line-height: 18px;
    }
  }

  &__section-text {
    max-width: 485px;
    margin: 0;
    margin-bottom: 39px;

    font-family: $font-family--helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    opacity: 0.6;

    @include vp--1279 {
      max-width: 440px;
      margin-bottom: 20px;
    }

    @include vp--767 {
      display: none;
    }
  }

  .hero__content-link:last-child {
    border: none;

    &:hover {
      background-color: $color-neon-blue;

      .btn {
        color: $color-han-purple;
        background-color: $color-default-white;
      }
    }

    .hero__content-section {
      margin-left: 0;
      margin-right: auto;

      display: flex;
      flex-direction: column;

      padding-left: 11%;

      @include vp--1279 {
        padding-left: 6.5%;
      }

      @include vp--767 {
        padding-left: 11px;
        padding-right: 11px;
      }

      .hero__section-head {
        max-width: 401px;
        margin-bottom: 32px;

        @include vp--1279 {
          max-width: 363px;
          margin-bottom: 16px;
        }

        @include vp--767 {
          max-width: 204px;
          margin-bottom: 14px;
        }
      }

      .hero__section-text {
        max-width: 396px;

        @include vp--1279 {
          max-width: 363px;
        }
      }

      .btn {
        margin-top: auto;
        margin-right: auto;

        opacity: 1;
        transition: none;
      }
    }
  }

  &__section__feature {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    color: $color-default-white;

    svg {
      flex-shrink: 0;

      margin-right: 18px;

      @include vp--767 {
        width: 22px;
        height: 22px;
        margin-right: 9px;
      }
    }
  }

  &__section__feature + .hero__section__feature {
    margin-top: 17px;

    @include vp--1279 {
      margin-top: 12px;
    }

    @include vp--767 {
      margin-top: 10px;
    }
  }

  .hero__feature-text {
    margin: 0;

    font-family: $font-family--helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    @include vp--1279 {
      font-size: 15px;
      line-height: 15px;
    }

    @include vp--767 {
      font-size: 14px;
      line-height: 14px;
    }
  }
}
