.subway-ways {
  position: relative;
  padding-bottom: 78px;
  padding-top: 38px;

  @include vp--767 {
    padding-bottom: 23px;
    padding-top: 42px;

    .container {
      padding: 0;
    }
  }

  &__img {
    position: absolute;
    top: 42px;
    right: -23px;
    z-index: -1;

    @include vp--767 {
      display: none;
    }
  }

  &__top {
    margin-bottom: 51px;

    @include vp--767 {
      margin-bottom: 25px;
      padding: 0 11px;
    }

    .title {
      margin-bottom: 25px;

      @include vp--767 {
        margin-bottom: 10px;
      }
    }
  }

  &__description {
    font-family: $font-family--helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: $color-black-pearl;
    margin: 0;
    padding-right: 330px;

    @include vp--1023 {
      padding-right: 50px;
    }

    @include vp--767 {
      font-size: 18px;
      line-height: 18px;
      padding-right: 11px;
    }
  }

  &__wrapper {
    display: flex;
    padding-left: 8px;

    @include vp--1023 {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding-left: 0;
    }
  }

  &__main {
    margin-right: 40px;

    @include vp--767 {
      margin-right: 0;
      padding: 0 11px;
    }
  }

  &__list {
    margin: 0;
    counter-reset: li;
    padding: 0;
    list-style: none;
    max-width: 730px;
  }

  &__item {
    position: relative;
    margin: 0;
    padding-left: 52px;
    padding-bottom: 35px;
    padding-top: 5px;

    @include vp--767 {
      padding-bottom: 17px;
      padding-top: 17px;
      padding-left: 39px;
    }

    &::before {
      content: "0" counter(li) ".";
      counter-increment: li;

      position: absolute;
      top: -6px;
      left: 0;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      color: $color-default-white;
      background: $color-black-pearl;
      font-weight: bold;
      text-align: center;
      padding-top: 7px;
      padding-left: 2px;

      @include vp--767 {
        width: 29px;
        height: 29px;
        padding-left: 2px;
        font-size: 11px;
        line-height: 11px;
        top: 9px;
        padding-top: 9px;
      }
    }

    &:nth-child(n + 10)::before {
      content: counter(li) ". ";
    }
  }

  &__item-text {
    font-family: $font-family--helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: $color-black-pearl;
    margin: 0;

    @include vp--767 {
      font-size: 14px;
      line-height: 14px;
    }
  }

  .swiper-container {
    @include vp--1023 {
      padding-bottom: 100px;
    }

    @include vp--767 {
      padding-bottom: 50px;
      width: 100%;
    }
  }
}
