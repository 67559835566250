// Colors
//---------------------------------
// https://www.htmlcsscolor.com/hex/334482

// Default

$color-default-black:             #000000;
$color-default-white:             #ffffff;
$color-transparent:               rgba(255, 255, 255, 0);

// Base

$color-grey:                      #888888;
$color-rolling-stone:             #787979;
$color-dolphin:                   #767677;
$color-raven-2:                   #6c717a;
$color-raven:                     #6b6c6e;
$color-han-purple:                #3718f5;
$color-cod-grey:                  #313233;
$color-neon-blue:                 #3d39fd;
$color-han-purple:                #3c1dfd;
$color-eclipse:                   #3b3b3b;
$color-nero:                      #222222;
$color-blue:                      #221dfd;
$color-eucalyptus:                #22b259;
$color-eucalyptus-2:              #e9f7ee;
$color-black-russian:             #141b28;
$color-maire:                     #1c1c1a;
$color-black-pearl-3:             #1d1e1f;
$color-black-pearl-2:             #1b1c1d;
$color-black-pearl:               #1a1b1c;
$color-white-smoke-2:             #f4f4f4;
$color-white-smoke:               #f2f2f2;
$color-snow:                      #fbfbfb;
$color-solitude:                  #e7e8e9;
$color-whisper-2:                 #e7e7e7;
$color-whisper:                   #eaeaea;
$color-gainsboro:                 #dedede;
$color-silver:                    #c4c4c4;
$color-fire-brick-2:              #f8e9e9;
$color-fire-brick:                #b41f1f;
$color-swamp:                     #1b1c1c;
$color-baltic-sea:                #3f4041;




// Gradient

$black-to-right:                    linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%);

// Typography
//---------------------------------

$font-family--helvetica:           "HelveticaNeueCyr", "Arial", sans-serif;
$font-family--montserrat:          "Montserrat", "Arial", sans-serif;
$font-family--roboto:              "Roboto", "Arial", sans-serif;


// https://www.artlebedev.ru/kovodstvo/sections/159/
$font-family--rouble:               "Rouble", "Arial", sans-serif;

// Animation
//---------------------------------

$trans--300:                        0.3s ease;
$trans--600:                        0.6s cubic-bezier(0.55, 0, 0.1, 1);

// Viewports
//---------------------------------

$vp--320:                           320px;
$vp--375:                           375px;
$vp--768:                           768px;
$vp--1024:                          1024px;
$vp--1280:                          1280px;
$vp--1440:                          1440px;
$vp--1920:                          1920px;

// Retina
//---------------------------------

$retina-dpi:                        144dpi;
$retina-dppx:                       1.5dppx;
