.icon-card {
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: $color-white-smoke;

    @include vp--1023 {
      width: 48px;
      height: 48px;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  &__title {
    margin: 20px 0;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: $color-black-pearl;

    @include vp--1023 {
      font-size: 18px;
      line-height: 18px;
      margin-top: 14px;
      margin-bottom: 10px;
    }
  }

  &__text {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    max-width: 340px;
    color: $color-black-pearl;
  }

  &--horizontal {
    display: flex;
    align-items: center;

    @include vp--767 {
      flex-direction: column;
      text-align: center;
    }

    .icon-card__img {
      flex-shrink: 0;
    }

    .icon-card__title {
      margin: 0;
      font-weight: 550;
      font-size: 14px;
      line-height: 20px;
    }

    .icon-card__text {
      color: rgba($color-black-pearl, 0.6);
    }

    .icon-card__wrapper {
      margin-left: 24px;

      @include vp--767 {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }

  &--dark {
    .icon-card__img {
      background-color: $color-maire;
    }
  }
}
