.technologies {
  padding-top: 75px;

  @include vp--767 {
    padding-top: 36px;
  }

  .container {
    &:nth-child(2n) {
      @include vp--767 {
        padding: 0;
      }
    }
  }

  &__table {
    padding-bottom: 70px;
    border-bottom: 1px solid $color-black-russian;

    @include vp--767 {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .title {
    margin-bottom: 24px;

    @include vp--767 {
      margin-bottom: 20px;
    }
  }

  &__img {
    width: 100%;
    height: 202px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include vp--767 {
        object-position: 30% 0;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 36px;
    margin-bottom: 36px;
    color: $color-black-pearl;

    @include vp--767 {
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 24px;
    }
  }

  &__column {
    width: calc(50% - 20px);

    @include vp--767 {
      width: 100%;
    }
  }

  &__about {
    margin: 0;
    max-width: 620px;
    font-size: 24px;
    line-height: 24px;

    @include vp--767 {
      max-width: 100%;
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__info {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 78px;
    display: flex;
    justify-content: space-between;
    color: $color-black-pearl-2;

    @include vp--767 {
      flex-direction: column;
      padding-top: 52px;
      margin-bottom: 32px;
    }
  }

  &__item {
    width: calc(50% - 20px);

    @include vp--767 {
      width: 100%;
    }
  }

  &__title {
    margin: 0;
    padding-bottom: 15px;
    font-weight: 550;
    font-size: 28px;
    line-height: 28px;
    border-bottom: 1px solid $color-black-russian;

    @include vp--767 {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__conditions {
    list-style: none;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 62px;
    padding: 0;
    font-size: 14px;
    line-height: 20px;

    @include vp--767 {
      margin-bottom: 40px;
    }
  }

  &__confition {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    @include vp--767 {
      margin-bottom: 10px;
    }
  }

  &__capture {
    margin: 0;
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
  }
}
