.badge {
  margin-top: 44px;

  @include vp--767 {
    margin-top: 36px;
  }
}

.badge__list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
}

.badge__item:not(:last-child) {
  margin-right: 36px;

  @include vp--767 {
    margin-right: 18px;
  }
}

.badge__item {
  margin-bottom: 36px;

  @include vp--767 {
    width: 49px;
    height: 49px;
  }
}
