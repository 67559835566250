.image-slider {
  max-width: 512px;

  @include vp--767 {
    max-width: fit-content;
    margin: 0 auto;
  }

  &.swiper-container {
    margin: 0;

    @include vp--1023 {
      padding-bottom: 55px;
    }
  }

  &__slides-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__slide {
    cursor: pointer;

    img {
      max-width: 512px;
      max-height: 340px;

      @include vp--767 {
        max-width: 375px;
        max-height: 250px;
        margin: 0 auto;
      }
    }
  }

  + .swiper-pagination-bullets {
    left: 50%;
    transform: translateX(-50%);
    padding-top: 18px;

    @include vp--767 {
      padding-top: 5px;
    }

    .swiper-pagination-bullet {
      margin-right: 22px;
      background: $color-black-pearl;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
