.news-articles {
  position: relative;
  padding-top: 80px;
  padding-bottom: 107px;
  border-bottom: 1px solid rgba($color-black-russian, 0.2);

  @include vp--1279 {
    padding-top: 45px;
    padding-bottom: 60px;
  }

  @include vp--767 {
    padding-top: 52px;
    padding-bottom: 44px;
  }

  .title {
    margin-bottom: 26px;

    font-size: 40px;
    line-height: 66px;

    @include vp--1279 {
      font-size: 36px;
      line-height: 66px;
      margin-bottom: 10px;
    }

    @include vp--767 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 19px;
    }
  }

  &__slider {
    position: relative;
  }

  &__list {
    list-style: none;

    margin: 0;
    padding-left: 0;
  }

  &__item {
    display: flex;

    @include vp--1279 {
      width: 294px;
    }

    @include vp--767 {
      width: 243px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 12px;
    height: 28px;

    opacity: 1;
    transition: opacity $trans--300;
    outline: 0;

    &:not([disabled]):not(.btn--disabled):focus,
    &:not([disabled]):not(.btn--disabled):hover {
      opacity: 0.7;
    }

    @include vp--767 {
      display: none;
    }

    svg {
      fill: none;
      stroke: $color-blue;

      transform: scale(1.2);
    }

    &.swiper-button-disabled {
      opacity: 1;

      svg {
        stroke: $color-black-pearl;
      }
    }

    &::after {
      display: none;
    }
  }

  .swiper-button-prev {
    right: 58px;
    left: auto;
    top: -51px;

    @include vp--1279 {
      top: -36px;
    }
  }

  .swiper-button-next {
    right: 0;
    top: -51px;

    @include vp--1279 {
      top: -36px;
    }
  }

  .swiper-scrollbar {
    position: absolute;
    bottom: -1px;

    width: 100%;
    height: 3px;

    background-color: $color-transparent;

    .swiper-scrollbar-drag {
      background-color: $color-black-pearl;

      cursor: pointer;

      transition: background-color $trans--300;

      &:hover {
        background-color: $color-blue;
      }
    }
  }

  .opacity-active {
    .news-articles-card__title {
      border-bottom: 2px solid $color-neon-blue;
    }

    .news-articles-card__description {
      color: $color-black-pearl;
    }
  }

  .news-articles__item {
    opacity: 0.4;

    transition: opacity $trans--600;
  }

  .news-articles__item.swiper-slide-active,
  .news-articles__item.swiper-slide-active + .news-articles__item,
  .news-articles__item.swiper-slide-active + .news-articles__item + .news-articles__item {
    opacity: 1;

    &.opacity {
      opacity: 0.7;
    }
  }
}

.news-articles--narrow.news-articles {
  padding: 54px 0 56px;
	border-bottom: none;
	background-color: $color-white-smoke;

  @include vp--767 {
    padding: 25px 0 51px;
  }

  .title {
    margin-bottom: 38px;

    font-size: 26px;
    line-height: 26px;

    @include vp--767 {
      margin-bottom: 15px;
      padding-left: 11px;

      font-size: 24px;
      line-height: 32px;
    }
  }

  .swiper-scrollbar {
    @include vp--1023 {
      left: 0;
    }
  }

  .news-articles__slider {
    @include vp--767 {
      padding-left: 11px;
      padding-right: 11px;
    }
  }

  .container {
    @include vp--767 {
      padding: 0;
    }
  }
}
