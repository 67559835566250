.custom-select {
  position: relative;
  z-index: 1;

  width: 100%;
  padding: 0;

  transform: translateZ(0);

  &__span {
    position: absolute;
    bottom: 15px;
    left: 21px;

    font-family: $font-family--helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-grey;

    pointer-events: none;

    transform-origin: left bottom;
    transition: transform .3s ease;
    transition: transform .3s ease,-webkit-transform .3s ease;

    @include vp--767 {
      left: 16px;
    }

    &.moved {
      transform: translateY(-17px) scale(0.75);
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 10px;

    transform: translateY(-50%);

    transition: transform $trans--300, opacity $trans--300;

    pointer-events: none;
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 10px;

    transform: translateY(-50%);

    transition: transform $trans--300, opacity $trans--300;

    pointer-events: none;
  }

  select {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  &__button {
    display: flex;

    width: 100%;
    min-height: 52px;
    padding: 24px 21px 8px 21px;
    border: none;
    border-bottom: 1px solid $color-raven;

    background-color: $color-white-smoke-2;

    outline: none;
    cursor: pointer;

    transition: border $trans--300;

    @include vp--767 {
      padding: 24px 16px 8px 16px;
    }

    &:hover {
      border-bottom: 1px solid $color-black-pearl;
    }

    &:focus {
      border-bottom: 1px solid $color-black-pearl;
    }
  }

  &__list {
    position: absolute;
    bottom: 0px;
    z-index: 1;

    width: 100%;
    max-height: 128px;
    margin: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid $color-raven;

    overflow-y: auto;

    list-style: none;

    background-color: $color-snow;

    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;

    transition: visibility $trans--300, opacity $trans--300;
  }

  &__item {
    padding: 7px 21px 6px 21px;

    font-family: $font-family--helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-grey;

    outline: none;
    cursor: pointer;

    transition: background-color $trans--300;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-white-smoke-2;
        color: $color-black-pearl;
      }
    }
  }

  &__error {
    position: absolute;
    top: calc(100% + 3px);
    left: 0;

    font-family: $font-family--helvetica;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    color: $color-fire-brick;

    opacity: 0;

    transition: opacity 0.1s ease;

    @include vp--1023 {
      top: calc(100% + 2px);
      line-height: 18px;
    }
  }

  &__text {
    flex-shrink: 0;
    width: 80%;
    overflow: hidden;

    font-family: $font-family--helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $color-black-pearl;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    opacity: 0;

    transition: opacity $trans--300 0.1s;

    br {
      display: none;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 16px;
    height: 9px;
    margin-left: auto;

    transition: transform $trans--300;

    svg {
      display: block;

      color: $color-default-black;
    }
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + 30px));
    }
  }

  &.is-open {
    button {
      background-color: $color-snow;

      border-bottom: 1px solid $color-snow;
    }

    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg);
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__span {
      transform: translateY(-17px) scale(.75);
    }
  }

  &.not-empty {
    .custom-select__button {
      border-bottom: 1px solid $color-black-pearl;
    }

    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      background-color: rgba($color-fire-brick, 0.1);
    }
  }
  
  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }
}
