.help {
  padding-top: 60px;
  padding-bottom: 114px;

  @include vp--767 {
    padding-top: 38px;
    padding-bottom: 26px;
  } 

  h2 {
    margin: 0;
    margin-bottom: 56px;

    font-family: HelveticaNeueCyr;
    font-size: 40px;
    font-weight: 550;
    line-height: 50px;
    letter-spacing: -0.01em;
    color: $color-black-pearl;

    @include vp--767 {
      margin-bottom: 24px;

      font-size: 24px;
      line-height: 32px;
    }    
  }

  .help__wrap {
    display: flex;
    justify-content: space-between;

    @include vp--767 {
      flex-direction: column;
      justify-content: start;
    }
  }

  .help__contacts-info {
    width: 22.83%;
    margin-right: 40px;

    @include vp--1023 {
      width: 30%;
    }

    @include vp--767 {
      width: 100%;
      margin-right: 0;
    }

    > * + * {
      margin-top: 28px;

      @include vp--767 {
        margin-top: 16px;
      }
    }

    .help__contacts-country {
      padding-bottom: 31px;
      border-bottom: 1px solid rgba($color-black-russian, 0.2);

      @include vp--767 {
        padding-bottom: 16px;
      }   
    }

    .help__country {
      margin-bottom: 22px;

      font-family: $font-family--helvetica;
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      color: $color-black-pearl;

      @include vp--767 {
        margin-bottom: 8px;

        font-size: 18px;
        line-height: 18px;
      }   
    }

    .help__contacts-text {
      font-family: $font-family--helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba($color-black-pearl, 0.7); 
    }

    .help__contacts {
      font-family: $font-family--helvetica;
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      color: $color-black-pearl;
 
      @include vp--767 {
        font-size: 16px;
      }  

      a:first-child {
        margin-bottom: 12px;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .help__contakts-link {
    margin-right: auto;

    opacity: 1;
    transition: opacity $trans--300;

    &:hover {
      opacity: 0.75;
    } 
  }

  .help__form {
    width: 65.63%;
    padding-top: 4px;

    @include vp--1023 {
      width: 58%;
    }

    @include vp--767 {
      padding-top: 2px;
      width: 100%;
    }
  }

  .help__contacts {
    display: flex;
    flex-direction: column;

    margin-bottom: 40px;
  }
}
