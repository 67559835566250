.awards {
  .container {
    padding-top: 32px;

    @include vp--767 {
      padding-top: 38px;
    }
  }

  &__wrapper {
    border-bottom: 1px solid $color-black-russian;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: -40px;
    width: calc(100% + 40px);
    display: flex;
    flex-wrap: wrap;

    @include vp--767 {
      flex-wrap: nowrap;
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      padding-bottom: 8px;
    }
  }

  &__item {
    width: calc(33.333333% - 40px);
    margin-right: 40px;
    margin-bottom: 27px;

    @include vp--767 {
      width: 100%;
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
}
