.news-articles-card {
  display: flex;
  flex-direction: column;

  @include vp--1279 {
    width: 294px;
  }

  @include vp--767 {
    width: 243px;
  }

  img {
    width: 402px;
    height: 165px;
    margin-bottom: 24px;

    object-fit: cover;

    @include vp--1279 {
      width: 294px;
      height: 165px;
      margin-bottom: 23px;
    }

    @include vp--767 {
      width: 243px;
      height: 116px;
    }
  }

  &__wrap {
    max-width: 402px;
  }

  &__title {
    margin: 0;
    margin-bottom: 13px;
    padding-right: 10px;
    padding-bottom: 4px;
    border-bottom: 2px solid $color-transparent;
    
    font-family: $font-family--helvetica;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: $color-black-pearl;

    @include vp--1279 {
      margin-bottom: 8px; 
      padding-right: 0;

      font-size: 21px;
      line-height: 21px;
    }

    @include vp--767 {
      margin-bottom: 2px;
      font-size: 17px;
      line-height: 17px;
    }
  }

  &__description {
    margin: 0;
    padding-right: 10px;

    font-family: $font-family--helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba($color-black-pearl, 0.7);

    @include vp--1279 { 
      padding-right: 0;
    }

    @include vp--767 {
      line-height: 18px;
    }
  }

  &__caption {
    margin: 0;
    margin-top: 6px;
    font-size: 18px;
    line-height: 18px;
    color: rgba($color-eclipse, 0.7);

    @include vp--767 {
      font-size: 17px;
      line-height: 17px;
    }
  }
}
