.subway-invest {
  margin-bottom: 115px;

  @include vp--767 {
    margin-bottom: 60px;
  }

  &__wrapper {
    position: relative;
    border-top: 1px solid $color-black-russian;
    z-index: -1;
    padding-left: 8px;

    @include vp--767 {
      padding-left: 0;
    }
  }

  img {
    position: absolute;
    top: -41px;
    right: -8px;
    z-index: -1;

    @include vp--767 {
      width: 110px;
      height: 151px;
      top: -19px;
      right: -16px;
    }
  }

  &__title {
    color: $color-black-pearl;
    margin-top: 55px;
    margin-bottom: 26px;
    padding-right: 215px;

    @include vp--767 {
      margin-top: 53px;
      margin-bottom: 10px;
      padding-right: 110px;
    }
  }

  &__description {
    font-family: $font-family--helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: $color-black-pearl;
    margin: 0;
    padding-right: 350px;

    @include vp--767 {
      font-size: 18px;
      line-height: 18px;
      padding-right: 109px;
    }
  }
}
