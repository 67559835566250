.main-nav {
  display: flex;
  align-items: center;
  max-width: 80%;

  @include vp--1023 {
    max-width: 100%;
  }

  &__toggle {
    display: none;

    position: absolute;
    top: 7px;
    left: 11px;

    width: 40px;
    height: 34px;
    padding-left: 0;

    background-color: $color-transparent;
    border: none;

    @include vp--1023 {
      display: block;
    }
  }
}

.main-nav__toggle-icon {
  color: $color-black-pearl;

  &--burger {
    width: 24px;
    height: 14px;
    margin-right: 10px;
  }

  &--cross {
    display: none;
    width: 19px;
    height: 19px;
  }
}

.main-nav__list {
  margin: 0 24px 0 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  min-height: 72px;

  @include vp--1279 {
    margin-right: 0;
  }

  @include vp--1023 {
    display: none;
  }
}

.main-nav__item {
  position: relative;
  padding: 0 37px;

  cursor: pointer;
  transition: color $trans--300;

  &.active {
    background-color: $color-white-smoke;

    svg {
      color: $color-default-black;
      opacity: 1;
    }

    @include vp--1023 {
      background-color: $color-transparent;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      .main-nav__link[href] {
        color: $color-blue;
        transition: color $trans--300;
      }

      svg {
        opacity: 1;
        color: $color-default-black;
        transform: rotate(180deg);
      }
    }
  }

  @include vp--1279 {
    padding-left: 21px;
    padding-right: 21px;
  }
}

.main-nav__link {
  padding: 18px 0 15px;
  display: flex;
	align-items: center;
  min-height: 72px;

  font-size: 14px;
  line-height: 14px;
  font-weight: 600;

  @include vp--1279 {
    padding-top: 15px;
  }

  svg {
    margin-left: 8px;

    fill: transparent;
    opacity: 0.4;

    @include vp--1279 {
      margin-left: 11px;
    }
  }
}

.main-nav__dropdown {
  display: none;

  position: absolute;
  top: 72px;
  left: 0;
  z-index: 100;

  &.active {
    display: flex;
  }
}

.main-nav__sublist {
  margin: 0;
  padding: 22px 0;
  list-style: none;

  min-width: 138.5%;

  font-size: 14px;
  line-height: 14px;

  background-color: $color-default-white;
  border-top: 1px solid $color-blue;

  .main-nav__subitem {
    padding: 10px 16px 10px 38px;
    transition: color $trans--300;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        .main-nav__sublink {
          color: $color-blue;
          transition: color $trans--300;
        }
      }
    }
  }
}

.main-nav__img {
  flex: 0 0 auto;

  border-top: 1px solid $color-blue;

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
