.projects {
  padding-top: 67px;

  @include vp--767 {
    padding-top: 54px;
  }

  &__slider {
    margin-top: 29px;
    padding-bottom: 83px;
    background-color: $color-maire;
    color: $color-default-white;

    @include vp--767 {
      margin-top: 8px;
      padding-bottom: 56px;
    }

    .container {
      position: relative;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .btn--opacity {
    transition: background-color $trans--300, color $trans--300;

    &:hover {
      opacity: 1;
      background-color: $color-default-white;
      color: $color-maire;
      transition: background-color $trans--300, color $trans--300;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    transition: opacity $trans--300;
    outline: none;

    &:hover {
      opacity: 0.7;
      transition: opacity $trans--300;
    }

    svg {
      fill: none;
      stroke: $color-default-white;
    }

    &.swiper-button-disabled {
      opacity: 0.3;
    }

    &::after {
      display: none;
    }
  }

  .swiper-button-prev {
    right: 81px;
    left: auto;
    top: 86px;

    @include vp--767 {
      right: 60px;
      top: 46px;
    }
  }

  .swiper-button-next {
    right: 24px;
    top: 86px;

    @include vp--767 {
      right: 4px;
      top: 46px;
    }
  }
}
