.articles-intro {
  position: relative;
  padding-top: 80px;
  padding-bottom: 127px;
  color: $color-default-white;
  background-color: $color-black-pearl;
  z-index: -1;

  @include vp--767 {
    padding-top: 134px;
    padding-bottom: 95px;
  }

  &__img {
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;

      @include vp--767 {
        object-fit: none;
        object-position: 82% 0;
      }
    }
  }

  .container {
    position: relative;
    z-index: 1;

    @include vp--767 {
      padding: 0 10px;
    }
  }

  .title {
    padding-bottom: 15px;
    color: $color-default-white;

    @include vp--767 {
      padding-bottom: 2px;
    }
  }

  &__about {
    margin: 0;
    max-width: 800px;
    font-size: 20px;
    line-height: 26px;

    @include vp--767 {
      max-width: 100%;
      margin: 24px 0;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.articles-main {
  margin-bottom: 70px;

  @include vp--767 {
    margin-bottom: 24px;
  }

  &__item {
    display: flex;
    border-bottom: 1px solid $color-silver;
    padding-bottom: 40px;
    padding-top: 40px;

    &:first-child {
      .articles-main__item-img {
        margin-top: -70px;

        @include vp--767 {
          margin-top: -95px;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    @include vp--1023 {
      flex-direction: column;
    }

    @include vp--767 {
      padding-top: 32px;
      padding-bottom: 33px;
    }
  }

  &__item-img {
    margin-right: 37px;

    @include vp--767 {
      margin-right: 0;
    }

    img {
      max-width: 402px;
      max-height: 235px;

      @include vp--767 {
        max-width: 100%;
        max-height: 193px;
        object-position: -7px -14px;
        object-fit: none;
      }
    }
  }

  &__item-wrapper {
    margin-top: auto;

    @include vp--1023 {
      margin-top: 24px;
    }
  }

  &__item-title {
    margin: 0;
    font-family: $font-family--helvetica;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: $color-black-pearl;

    @include vp--1279 {
      font-size: 21px;
      line-height: 21px;
    }

    @include vp--767 {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__item-description {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 24px;

    font-family: $font-family--helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba($color-black-pearl, 0.7);

    @include vp--1279 {
      margin-bottom: 14px;
    }

    @include vp--767 {
      margin-top: 15px;
      margin-bottom: 17px;
      line-height: 18px;
    }
  }

  .btn {
    justify-content: left;
    max-width: 210px;
  }

  .btn--transparent {
    transition: color $trans--300;
    opacity: 1 !important;

    &:hover {
      background-color: $color-black-pearl;
      border-color: $color-black-pearl;
      color: $color-default-white;
      transition: color $trans--300;
      transition: background-color $trans--300;
    }

    &:active {
      background-color: transparent;
      color: $color-black-pearl;
      transition: color $trans--300;
      transition: background-color $trans--300;
    }
  }
}
