.subway-intro {
  .intro__about {
    max-width: 100%;

    @include vp--767 {
      display: none;
    }
  }

  &__about-mobile {
    display: none;

    @include vp--767 {
      display: block;
      max-width: 100%;
      margin: 0;
      font-size: 18px;
      line-height: 18px;
      margin-top: 26px;
    }
  }

  .btn--link-back {
    @include vp--767 {
      margin-bottom: 60px;
    }
  }
}
