@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat-Medium.woff2") format("woff2"),
    url("../fonts/Montserrat-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "HelveticaNeueCyr";

  font-display: swap;
  src:
    url("../fonts/HelveticaNeueCyr-Thin.woff2") format("woff2"),
    url("../fonts/HelveticaNeueCyr-Thin.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "HelveticaNeueCyr";

  font-display: swap;
  src:
    url("../fonts/HelveticaNeueCyr-Roman.woff2") format("woff2"),
    url("../fonts/HelveticaNeueCyr-Roman.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "HelveticaNeueCyr";

  font-display: swap;
  src:
    url("../fonts/HelveticaNeueCyr-Medium.woff2") format("woff2"),
    url("../fonts/HelveticaNeueCyr-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "HelveticaNeueCyr";

  font-display: swap;
  src:
    url("../fonts/HelveticaNeueCyr-Bold.woff2") format("woff2"),
    url("../fonts/HelveticaNeueCyr-Bold.woff") format("woff");
}
