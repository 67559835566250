.tabs {
  max-width: 100%;
}

.tabs__controls {
  display: flex;
  width: 100%;
  margin-bottom: 40px;

  @include vp--767 {
    margin-bottom: 24px;
  }
}

.tabs__control {
  max-width: none;
  width: 50%;
  min-width: auto;
  min-height: 40px;
  padding: 9px;
  border: 1px solid $color-black-pearl-3;

  font-family: $font-family--helvetica;
  font-weight: 550;
  line-height: 20px;
  color: #1D1E1F;

  cursor: pointer;
  opacity: 1;
  transition: opacity $trans--300, border-color $trans--300, background-color $trans--300, color $trans--300;
  background-color: $color-transparent;

  &.is-active {
    color: $color-default-white;
    pointer-events: none;
    background-color: $color-black-pearl-3;
  }
}

.tabs .tabs__control {
  border-radius: 0;

  font-size: 14px;
  line-height: 20px;

  &:hover:not(.is-active) {
    opacity: 1;
    background-color: rgba($color-black-pearl-3, 0.1);
    color: rgba($color-black-pearl-3, 0.7);
    border-color: $color-transparent;
  }
}

.tabs__content {
  transition: height 0.3s;
}

.tabs__element {
  width: 100%;

  p {
    color: $color-black-pearl;
  }
}
