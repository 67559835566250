.articles-preview {
  padding: 22px 0 43px;
  color: $color-black-pearl;
  background-color: $color-white-smoke;

  @include vp--767 {
    padding-bottom: 0;
  }
}

.articles-preview__main-title {
  margin: 34px 0 36px;

  font-size: 26px;
  line-height: 26px;
  font-weight: 600;

	@include vp--767 {
		margin: 0 0 20px 11px;

		font-size: 24px;
		line-height: 32px;
	}
}

.articles-preview__title {
  margin: 23px 0 20px;

  font-size: 24px;
  line-height: 24px;

  @include vp--767 {
    margin-bottom: 5px;

    font-size: 17px;
    line-height: 17px;
  }
}

.articles-preview__text {
  font-size: 14px;
  line-height: 20px;

  @include vp--767 {
    margin: 0 0 10px;

    line-height: 18px;
  }
}

.articles-preview__list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  justify-content: space-between;

  @include vp--767 {
    overflow-y: auto;
    padding: 0 11px 41px 11px;

    &::-webkit-scrollbar {
      background-color: rgba(20, 27, 40, 0.2);
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-black-pearl;
    }
  }
}

.articles-preview__item {
  width: calc(33.3% - 25px);

  @include vp--1023 {
    min-width: 243px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include vp--767 {
    margin-right: 25px;
  }
}

.articles-preview__link {
  img {
    width: 100%;
    max-height: 165px;
    object-fit: cover;

    @include vp--767 {
      max-height: 116px;
    }
  }
}
