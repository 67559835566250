.article {
  padding: 53px 0 117px;

  color: $color-black-pearl;

  @include vp--767 {
    padding-top: 23px;
    padding-bottom: 40px;
  }

  p {
    margin: 0 0 20px;
    max-width: 1175px;

    font-size: 14px;
    line-height: 20px;
  }

  p.article__lead-text {
    margin: 0 0 58px 0;

    font-size: 24px;
    line-height: 24px;

    @include vp--767 {
      margin-bottom: 33px;

      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
    }
  }

  h2 {
    margin: 64px 0 18px;
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;
    letter-spacing: -0.01em;

    @include vp--1023 {
      display: none;
      margin: 55px 0 22px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  h3 {
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
    color: $color-swamp;

    @include vp--1023 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
    }
  }

  h4 {
    margin: 32px 0;
    max-width: 63%;

    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
    color: $color-black-russian;

    @include vp--1023 {
      margin: 40px 0 24px;
      max-width: 100%;

      font-size: 18px;
      line-height: 18px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    @include vp--1023 {
      display: none;
    }
  }

  &__title-icon {
    display: grid;
    width: 56px;
    height: 56px;
    margin-right: 16px;
    flex-shrink: 0;

    border-radius: 50%;
    background-color: $color-default-black;
    color: $color-default-white;

    @include vp--1023 {
      width: 48px;
      height: 48px;
      margin-right: 14px;
    }

    svg {
      place-self: center;
      margin-left: 0;

      @include vp--1023 {
        width: 27px;
        height: 27px;
      }
    }
  }
}

.article__lead {
  display: flex;
  justify-content: space-between;

  @include vp--1023 {
    flex-direction: column-reverse;
  }

  &-wrapper {
    width: calc(50% - 32px);

    @include vp--1023 {
      width: 100%;

      margin-top: 30px;
    }

    @include vp--767 {
      p {
        line-height: 18px;
      }
    }
  }

  &-img {
    margin-left: 72px;

    @include vp--1023 {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;

      & img {
        width: 100%;
        min-height: 250px;
        object-fit: cover;
      }
    }
  }

  p {
    max-width: 97%;
    margin: 0;

    @include vp--1023 {
      max-width: 100%;
    }
  }
}

.article__accordion-element.is-open {
  @include vp--1023 {
    padding-bottom: 20px;
  }

  .article__accordion-icon {
    transform: rotate(90deg);
    color: $color-black-pearl;
    align-self: center;
  }
}

svg.article__accordion-icon {
  fill: transparent;

  flex-shrink: 0;
  color: $color-blue;

  display: none;

  @include vp--1023 {
    display: block;
    position: absolute;
    top: 4px;
    right: 1px;
  }
}

.article__accordion-btn {
  display: flex;
  justify-content: flex-start;
  padding: 40px 0 40px;
  display: none;

  border: none;

  .article__title-text {
    @include vp--1023 {
      position: relative;
      width: 100%;
      padding-right: 48px;

      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      text-align: left;
    }
  }

  @include vp--1023 {
    display: flex;

    &:not([disabled]):not(.btn--disabled):focus,
    &:not([disabled]):not(.btn--disabled):hover {
      opacity: 1;
    }
  }
}

.article__accordion-element {
  @include vp--1023 {
    border-bottom: 1px solid $color-black-pearl;
  }
}

.article__accordion-content {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid $color-black-russian;

  &--illustrated {
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: none;
  }

  @include vp--1023 {
    flex-direction: column;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

.article__column--wide {
  width: calc(66% - 32px);

  @include vp--1023 {
    width: 100%;
  }
}

.article__column--narrow {
  flex: 0 0 auto;
  margin-left: 32px;
  margin-top: 68px;

  @include vp--1023 {
    margin-left: 0;
    margin-top: 20px;
  }
}

.article div[data-accordion=content] {
  @media(min-width: 1024px) {
    max-height: fit-content !important;
  }
}

.article--one {
  padding: 53px 0 66px;

  @include vp--767 {
    padding-top: 23px;
    padding-bottom: 40px;
  }

  h2 {
    margin-bottom: 40px;

    font-weight: 600;

    @include vp--1023 {
      margin-top: 20px;
      display: block;
    }
  }

  h3 {
    margin: 0;

    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: $color-black-pearl;
  }

  h4 {
    margin: 48px 0 36px;
    max-width: 100%;

    color: $color-black-pearl;
  }

  p {
    max-width: 100%;
    margin: 0;
  }
}

.article .article__subject {
  &-wrapper {
    max-width: 70%;

    @include vp--1023 {
      max-width: 100%;
    }

    &--illustrated {
      max-width: 100%;
      display: flex;
      flex-direction: row-reverse;

      @include vp--1023 {
        flex-direction: column;
      }
    }
  }

  &-lead {
    max-width: 77%;
    margin-bottom: 34px;

    font-size: 24px;
    line-height: 24px;

    @include vp--1023 {
      max-width: 100%;

      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
    }
  }

  &-counter {
    margin-bottom: 48px;

    @include vp--1023 {
      margin-bottom: 33px;
    }
  }

  &-list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto auto;
    row-gap: 24px;
    column-gap: 30px;
    max-width: 1030px;

    @include vp--1023 {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }

    &--column {
      display: flex;
      flex-direction: column;
    }

    &--reversed {
      grid-template-columns: repeat(3, 33%);
    }

    li {
      display: flex;
      align-items: center;

      &:nth-child(2n) {
        grid-row: 2;
      }
    }
  }

  &-text {
    margin-bottom: 0;
    max-width: auto;

    font-size: 18px;
    line-height: 18px;
    color: $color-black-pearl;

    @include vp--1023 {
      font-size: 14px;
      line-height: 14px;
    }
  }

  &-dash {
    margin-right: 18px;
  }

  &-number {
    width: 37px;
    height: 37px;
    display: grid;
    place-items: center;
    padding-left: 3px;
    margin-right: 18px;
    flex-shrink: 0;

    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    color: $color-default-white;

    background-color: $color-swamp;
    border-radius: 50%;

    @include vp--1023 {
      width: 29px;
      height: 30px;
      padding-left: 2px;
      margin-right: 10px;

      font-size: 11px;
      line-height: 11px;
      font-weight: 600;
    }
  }
}

.article__img {
  margin-left: 72px;
  flex-shrink: 0;

  &--top {
    margin-top: -80px;

    @include vp--1023 {
      margin-top: 0;
    }
  }

  &--left {
    margin-left: 0;
    margin-right: 72px;
  }

  &--large-margin {
    margin-right: 110px;
    margin-left: 0;
  }

  @include vp--1023 {
    width: 100%;
    margin-bottom: 23px;
    margin-left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.article {
  .text-link {
    color: $color-blue;
    text-decoration: underline;
    transition: text-decoration $trans--300;

    &:hover,
    &:focus {
      text-decoration: none;
      transition: text-decoration $trans--300;
    }
  }
}

.article__btn.btn {
  display: none;

  @include vp--1023 {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 48px;

    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: -0.01em;
    text-align: left;

    border: none;

    &:not([disabled]):not(.btn--disabled):focus,
    &:not([disabled]):not(.btn--disabled):hover {
      opacity: 1;
    }
  }
}

.article__icon {
  margin-right: 5px;
  align-self: flex-start;
  margin-top: 10px;

  fill: transparent;
  color: $color-blue;
}

.article__element.is-open .article__icon {
  color: $color-black-pearl;
  transform: rotate(90deg);
}

.article__element {
  @include vp--1023 {
    padding-bottom: 20px;

    border-bottom: 1px solid $color-black-pearl;
  }
}

.article__block {
  display: flex;

  @include vp--1023 {
    flex-direction: column-reverse;
  }
}

.article__subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @include vp--1023 {
    margin-bottom: 20px;
  }

  &:not(:first-of-type) {
    margin-top: 56px;

    @include vp--1023 {
      margin-top: 36px;
    }
  }

  &-icon {
    width: 56px;
    height: 56px;
    display: grid;
    place-items: center;
    margin-right: 15px;

    fill: transparent;
    color: $color-default-black;

    background-color: $color-white-smoke;
    border-radius: 50%;

    @include vp--1023 {
      width: 48px;
      height: 48px;
    }
  }

  &-text {
    margin: 0;
  }
}

.article__list {
  list-style: none;
  padding: 0 0 0 10px;
  margin: 0 0 48px;

  width: 100%;
  max-width: 1175px;

  font-size: 18px;
  line-height: 18px;

  &--inner {
    margin-bottom: 16px;
  }

  &--margin {
    margin-top: 24px;
  }

  @include vp--1023 {
    margin-bottom: 0;

    font-size: 16px;
    line-height: 16px;
  }

  li {
    margin-bottom: 24px;
    text-indent: -10px;

    &::before {
      content: "-";
      margin-right: 5px;
    }

    @include vp--1023 {
      margin-bottom: 21px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.article__content {
  @include vp--1023 {
    margin-top: -23px;
  }
}

.article__bold-text {
  h4 {
    margin-top: 0;

    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
    color: $color-swamp;
  }

  p {
    max-width: 622px;

    font-size: 24px;
    line-height: 24px;

    color: $color-black-russian;

    @include vp--1023 {
      font-size: 20px;
      line-height: 22px;
    }
  }
}

.article__block--columns {
  margin-bottom: 25px;
}

.article__block--margin {
  margin-bottom: 20px;
}

.article__block--columns > div {
  width: calc(50% - 90px);

  @include vp--1023 {
    width: 100%;
  }
}

.article__subject-wrapper p:last-of-type {
  margin-bottom: 14px;
}

.article-wrapper p:last-of-type {
  margin-bottom: 0;

  @include vp--1023 {
    margin-bottom: 20px;
  }
}


