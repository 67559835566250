.header {
  position: relative;

  background-color: $color-default-white;

  .btn--search {
    @include vp--1279 {
      svg {
        margin-left: 17px;
      }
    }

    @include vp--1023 {
      position: absolute;
      top: 6px;
      right: 12px;

      svg {
        opacity: 1;
      }
    }
  }
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include vp--1023 {
    justify-content: center;
  }
}

.header__logo {
  display: block;
  padding-top: 5px;

  @include vp--1279 {
    padding-top: 0;
    padding-bottom: 3px;

    img {
      width: 130px;
    }
  }

  @include vp--1023 {
    padding-bottom: 0;

    img {
      width: 104px;
      height: 48px;
    }
  }
}

.header__logo[href] {
  transition: opacity $trans--300;

  &:hover,
  &:focus {
    opacity: 0.8;
    transition: opacity $trans--300;
  }
}

.header.menu-opened {
  .main-nav__inner {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    height: calc(100 * var(--vh, 1vh));
  }

  .main-nav__wrapper {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));

    overflow: hidden;

    background-color: $color-black-pearl;
  }

  .main-nav__list {
    display: block;
    margin-right: 0;
    padding: 30px 11px 21px;
  }

  .main-nav__item {
    padding: 0;
    margin-bottom: 11px;

    &--dropdown {
      margin-bottom: 23px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .main-nav__link {
    padding: 10px 0;
    width: fit-content;
    min-height: auto;

    font-size: 15px;
    line-height: 15px;
    color: $color-default-white;
    font-weight: 700;

    svg {
      display: none;
    }
  }

  .main-nav__dropdown {
    position: static;
    display: block;
    margin-top: 5px;

    .main-nav__img {
      display: none;
    }
  }

  .main-nav__sublist {
    padding: 0;

    background-color: $color-black-pearl;
    border-top: none;
  }

  .main-nav__subitem {
    margin-bottom: 10px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .main-nav__sublink {
    font-size: 15px;
    line-height: 15px;
    color: $color-default-white;
  }

  .main-nav__toggle-icon {
    &--cross {
      display: block;
    }

    &--burger {
      display: none;
    }
  }

  .office {
    display: block;
  }
}
