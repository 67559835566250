.wifi {
  background-color: $color-black-pearl;
  color: $color-default-white;
  border-top: 1px solid rgba($color-default-white, 0.1);

  @include vp--767 {
    position: relative;
    border-top: 0;
    padding: 32px 0;

    &::after {
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: "";
      background-color: rgba($color-default-white, 0.1);
    }
  }

  &__top {
    display: flex;
    align-items: center;
    margin-right: 80px;
    margin-bottom: 14px;

    @include vp--1439 {
      margin-right: 30px;
    }

    @include vp--767 {
      margin: 0;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: rgba($color-white-smoke, 0.1);
    flex-shrink: 0;

    @include vp--767 {
      width: 48px;
      height: 48px;
    }

    svg {
      fill: none;
    }
  }

  &__title {
    margin: 0;
    margin-left: 18px;
    max-width: 340px;
    font-weight: 550;
    font-size: 26px;
    line-height: 26px;

    @include vp--1023 {
      max-width: 300px;
      font-size: 22px;
    }

    @include vp--767 {
      margin: 0;
      margin-left: 15px;
      max-width: 100%;
      font-size: 18px;
      line-height: 18px;
    }
  }

  &__text {
    max-width: 490px;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.6;

    @include vp--1439 {
      max-width: 440px;
    }

    @include vp--1023 {
      max-width: 360px;
    }

    @include vp--767 {
      display: none;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    width: calc(100% + 91px);
    margin-left: -91px;

    @include vp--1439 {
      width: 100%;
      margin-left: 0;
    }

    @include vp--767 {
      display: block;
    }
  }

  &__item {
    width: 50%;

    @include vp--767 {
      width: 100%;
      padding-left: 11px;
    }

    &:first-child {
      width: 55%;
      border-right: 1px solid rgba($color-default-white, 0.1);

      @include vp--1439 {
        width: 50%;
      }

      @include vp--767 {
        border-right: none;
      }

      .wifi__link {
        align-items: flex-end;
        padding-right: 140px;

        @include vp--1439 {
          padding-left: 33px;
          padding-right: 200px;
        }

        @include vp--1279 {
          padding-left: 33px;
          padding-right: 41px;
          align-items: flex-start;
        }

        @include vp--767 {
          margin-right: 0;
          margin-bottom: 64px;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-top: 46px;
    padding-bottom: 68px;
    padding-left: 92px;
    color: $color-default-white;

    @include vp--1279 {
      padding-left: 33px;
      padding-right: 33px;
    }

    @include vp--1023 {
      padding-left: 40px;
    }

    @include vp--767 {
      width: 100%;
      padding: 0;
    }


    &:hover {
      transition: background-color $trans--300;
      background-color: $color-cod-grey;

      @include vp--767 {
        background-color: $color-transparent;
      }
    }
  }
}
